import Router from 'vue-router';
import SourceReport from '../views/DashBoard/SourceReport.vue'
import SalesReport from '../views/DashBoard/SalesReport.vue'
// import AuthService from '../services/AuthService'




const routes = [
    {
        path: '/locations/:location_id',
        name: 'source-report',
        component: SourceReport,
        props: true,

    },
    {
        path: '/locations/:location_id/pipelines/:pipeline_id/source-report',
        name: 'pipeline-source-report',
        component: SourceReport,
        props: route => Object.assign({}, route.query, route.params),
        meta: {
            requirePipeline: true
        }
    },
    {
        path: '/locations/:location_id/sales',
        name: 'sales-report',
        component: SalesReport,
        props: true,

    },
    {
        path: '/locations/:location_id/pipelines/:pipeline_id/sales-report',
        name: 'pipeline-sales-report',
        component: SalesReport,
        props: route => Object.assign({}, route.query, route.params),
        meta: {
            requirePipeline: true
        }
    },
]

const router = new Router({
  mode: 'history',
  routes
});


// router.beforeEach( async (to, from, next) => {
//     const token = localStorage.getItem('userToken');
//     const location_id = window.location.href.split('/')[4]

//     if(token){
//         next();
//     }
//     else {
//         const authService = new AuthService()
//         await authService.upsertToken(location_id);
//         next();
//     }
// });

export default router;
