module.exports = {
    SUBSRIPTIONS: {
        trial: {
            message: "You are currently on a 14 day trial account.",
            color: '#3E81D0'
        },
        cancelled: {
            message: "You have cancelled your account and data will stop collecting after 14 days unless you reactive your account",
            color: '#E51B1B'
        },
        free: {
            message: "Report not found. Input either your Agency Key or Location Keys to generate your report.",
            color: '#E51B1B'
        },
        trial_data_collection_stopped: {
            message: "Your trial is over and we are no longer collecting data for your account. Please upgrade to an active subscription.",
            color: '#E51B1B'
        },
        cancelled_data_collection_stopped: {
            message: "We are no longer collecting data for this account. Please upgrade to an active subscription.",
            color: '#E51B1B'
        },
    }
}