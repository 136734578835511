<template>
   <div class ="expand-panel" width="100%"  v-click-outside="hideConfigDrawer">

		<div>
			<v-btn 
				v-if="!drawer"
				color="#FFFFF"
				width="50px"
				height="20px"
				plain
				raised
				text
				class="expand-button mt-5"
				@click="expandConfigDrawer">
					<v-icon class="mr-3" x-large>mdi-view-dashboard-outline</v-icon>
			</v-btn>
		</div>
		<div v-if="drawer" style="height: 700px; overflow-y: auto">
			<v-expansion-panels  
				style="height: auto; width: 100%"
				class="overflow-y-auto set-box"
				dense
				elevation="0"
			>
				<v-expansion-panel class="panel-color" @click="onExpansionPanelClick">
					<v-expansion-panel-header  class="rounded-lg column-expanation root-expansion-panel-header-colour">
						<v-icon dark right medium>mdi-view-dashboard-outline</v-icon><span>Edit Columns</span>
						<template v-slot:actions>
							<v-icon color="primary" class="mr-2">
								$expand
							</v-icon>	
						</template>	
					</v-expansion-panel-header>

					<v-expansion-panel-content dense v-if="hasItems" >
						<v-checkbox
							label="Show All"		
							v-model="selectAll"
							class="input-checkbox border-botton-check-box select-all mt-5"
							@click="toggleSelectAll"
							:value="selectAll"
							v-if="hasItems"
							color="info"
						/>

						<template v-for="item in items">
								<v-checkbox
									:label="item.text.toLowerCase()"
									v-if="!isStageColumn(item)"
									:key="item.value"
									v-model="checked[item.value]"
									class="input-checkbox border-botton-check-box"
									:disabled="disabledValue(item.value)"
									:value="item.value"
									color="info"
								/>
							<v-expansion-panels 
								elevation="0"
								v-if="isStageColumn(item)" 
								:key="item.stage_name" 
								id="stage-expansion-panels" 
								class="stage-expansion-panels">		
								<v-expansion-panel>
									<v-expansion-panel-header class="expansion-panel-header border-botton-check-box">
										<v-checkbox
											:label="item.stage_name"		
											v-model="stage_column_index[item.stage_rank]"
											@click="selectAllStage($event,item.stage_rank)"
											class="stage-root-checkbox ml-3 mt-1"
											style="font-size: 12px;"
											width="80%"
										/>
									<template v-slot:actions>
										<v-icon color="primary" class="mr-2">
											$expand
										</v-icon>	
									</template>
									</v-expansion-panel-header>
									<v-expansion-panel-content >
										<v-checkbox
											:label="text=='ROI'? text : text.toLowerCase()"
											v-model="checked[value]"
											v-bind:stage_updated="stage_updated"
											v-for="{text,value} in item.stage_columns" :key="value"
											class="input-checkbox ml-4 set-white-color-background"
										/>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</template>	
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<div class="config-save-panel">
				<v-btn
					color="primary"
					@click="updateChange"
					v-if="showSave"
					:bottom='true'
					class="save-config rounded-pill mt-2 mb-2 mr-4">
						apply
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ColumnFilterConfig',
	props: {
		items: {
			type: Array,
		},
		selectedItems: {
			type: Array,
		},
		default_columns:{
			type: Array
		},
		drawer:{
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			checked: {},
			stage_updated: 0,
			selectAll: false,
			stage_column_index: {},
			showSave: false,
			default_selected: {},
		}
	},

	mounted() {
		this
		.selectedItems
		.forEach( item  => {
			this.checked[item] = item
		});
		this.buildStageIndex()
		this.checkSelection()
	},
	computed: {
		checkedItems(){
			return this.checked
		},
		allItems(){
			return this.items
		},
		disabledColums(){
			return  []
		},
		selectSome () {
			return this.selectedItems.length > 0 && !this.selectAll
		},
		hasItems(){
			return this.items.length > 0
		},
		icon() {
			if (this.selectAll) return 'mdi-close-box'
			if (this.selectSome) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},
	},
	watch: { 
		selectedItems: function(selected) { 
			selected
			.forEach( item  => {
				this.checked[item] = item
			});
			this.buildStageIndex()
			this.checkSelection()
        }
     },
	methods: {
		buildStageIndex(){
			this
			.items
			.forEach(item =>{
				if(this.isStageColumn(item)){
					this.stage_column_index[item.stage_rank] = true
				}
			})
		},
		selectAllStage($event,stage_rank){

			$event.cancelBubble = true;

			if(this.stage_column_index[stage_rank]){
				const stage = this.items.filter((item) => {
					return item.stage_rank == stage_rank
				});

				stage[0].stage_columns.forEach(stage_column => {
					this.checked[stage_column.value] = stage_column.value
				})
			}
			else
			{
				const stage = this.items.filter((item) => {
					return item.stage_rank == stage_rank
				});
				stage[0].stage_columns.forEach(stage_column => {
					this.checked[stage_column.value] = null
				})
			}
			this.stage_updated += 1
		},
		checkSelection(){
			const keys = Object.keys(this.checked);
			const filtered = keys.filter((key) => {
				return !!this.checked[key]
			});
			this.selectAll = Object.keys(this.checked).length == filtered.length 
		},
		isStageColumn(value){
			return !!value.stage_columns
		},
		populateDefaultColumns(){
			this.default_selected={}
			this
			.default_columns
			.forEach( item  => {
				this.default_selected[item] = item
				
			});
			return this.default_selected
		},
		disabledValue(value){
			return this.default_columns.includes(value)
		},
		toggleSelectAll () {
			if (this.selectAll) {
				this.items.forEach( item =>{
					if(this.isStageColumn(item)){
						this.stage_column_index[item.stage_rank] = true
						item.stage_columns.forEach(colums =>{
							this.checked[colums.value] = colums.value
						})
					}
					else
					{
						this.checked[item.value] = item.value
					}
				});
			} else {
				this.checked  = this.populateDefaultColumns()

				this.items.forEach( item => {
					if(this.isStageColumn(item)){
						this.stage_column_index[item.stage_rank] = null
					}
				});
			}
		},
		updateChange(){
			const keys = Object.keys(this.checked);
			const filtered = keys.filter((key) => {
				return !!this.checked[key]
			});
			this.$emit('update', filtered )
		},
		hideConfigDrawer(){
			this.$emit('hideConfigDrawer')
		},
		expandConfigDrawer(){
			this.showSave = true
			this.$emit('showConfigDrawer')
		},
		onExpansionPanelClick(event) {
			if(event.target.classList.contains('v-expansion-panel-header--active')) {
				this.expandConfigDrawer()
			} else {
				this.showSave = true
			}
		}
	}
}
</script>

<style scoped>
.left-align{
	float: left;
}
.select-all{
	background-color: #FFFF;
	margin-top: 8px;
	margin-bottom: 5px;
}
.save-config{
	float: right;
	right: 1px;
	right: 0;
}
.expand-button{
	padding: 0px;
	right: 0px;
	top: 0px;
	color: #5094DD;
}
.set-overflow{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.v-expansion-panel-content__wrap{
	padding-left: 0px !important;
	background-color: #F3F8FB;
}
.v-input--selection-controls{
	margin-top: 1px;
}
::v-deep .stage-expansion-panels .set-white-color-background{
 background-color: #FFFFFF;
}
.input-checkbox{
	padding-top: 6px;
	padding-right: 5px;
	padding-bottom: 4px;
	padding-left: 10px;
	font-size: 12px !important;
	background: #F3F8FB 0% 0% no-repeat padding-box;
	opacity: 1;
}
.stage-expansion-panels{
	box-shadow: none;
	position: initial;
}

.expansion-panel-header{
	color: #5094DD;
	background: #F3F8FB 0% 0% no-repeat padding-box;
}
.border-botton-check-box{
	border-bottom: 1px solid #5094DD80;
}
::v-deep .input-checkbox .v-label {
 font-size: 12px;
 background-color: #F3F8FB;
 color: #0058B7;
 text-transform: capitalize;
}
::v-deep .input-checkbox .v-input__slot {
 margin-bottom: 4px;
}
::v-deep .select-all .v-input__slot {
	margin-bottom: 4px;
	border: 1px solid rgb(255, 255, 255);
	border-radius: 6px;
	padding: 2px;
	background-color:rgb(255, 255, 255) ;
}
.stage-root-checkbox{
	width: 100%;
	word-break: break-all;
	font-size: 12px !important;
	color: #f3f8fb;
	min-height: 42px;
}
::v-deep .stage-root-checkbox .v-label{
	font-size: 12px !important;
	color: #0058B7 !important;

}
::v-deep .stage-root-checkbox .v-input__slot{
	background-color: #f3f8fb;
	background-repeat: no-repeat;
	width: 80%;
}
::v-deep .select-all .v-label  {
	background-color:rgb(255, 255, 255) ;
}
::v-deep .stage-expansion-panels .input-checkbox .v-label  {
	border: 1px solid #0091EA;
	min-height: 20px;
	padding: 7px;
    word-break: break-all;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 10px;
}
::v-deep .stage-expansion-panels .v-expansion-panel-content__wrap {
	background: #FFFFFF 0% 0% no-repeat padding-box;
}
::v-deep .stage-expansion-panels .input-checkbox {
	background: #FFFFFF 0% 0% no-repeat padding-box;
}
::v-deep .input-checkbox .v-messages {
 min-height: 5px;
 padding: 2px;
}
.v-expansion-panel--active > .v-expansion-panel-header{
	min-height: 20px !important;
}
::v-deep .v-expansion-panel-content__wrap{
	padding: 0px;
}
.root-expansion-panel-header-colour{
	background-color: #5094DD;
	color: #FFFFFF;
	font-weight: 600;
}
.v-expansion-panel-header{
	font-size: 12px;
	padding: 0px;

}
.v-expansion-panel-content{
	background: #F3F8FB 0% 0% no-repeat padding-box;
	font-size: 12px;
	color: #5094DD !important;
}
.v-label{
	font-size: 12px;
	text-transform: capitalize;
}
.set-box{
	padding: 10px;
}
.config-save-panel{
	z-index: 222;
	opacity: 1;
}
.panel-color {
	background: #F3F8FB 0% 0% no-repeat padding-box;
	opacity: 1;
	box-shadow: none;
}
.v-expansion-panel {
	background: #F3F8FB 0% 0% no-repeat padding-box;
	opacity: 1;
	box-shadow: none;
}
.column-expanation{
  height: 40px;
}
.v-expansion-panel-header{
	min-height: 20px;
}
.stage-root-checkbox .v-messages{
	min-height: 0px;
}
	/* .expand-panel{
	float: right;
} */	

</style>