	<template>
  <div>
    <v-row no-gutters>
      <v-col cols="8"></v-col>
      <v-col cols="2">
        <v-select
          :items="reports"
          v-model="selectedReport"
          label="Select Report"
          outlined
          class="mt-2 right-align"
          dense
          @change="gotoSalesReport()"
        >
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          class="mr-5 mt-2 right-align ml-10 app-text-background"
          v-model="selectedPipeline"
          :items="pipelines"
          outlined
          width="40"
          dense
          item-text="pipeline_name"
          item-value="pipeline_id"
          label="Select Pipeline"
          no-data-text="Loading your pipelines"
          :loading="pipeline_loading"
          @change="selectPipeline"
          @click="loadPipelines"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="pipeLineSelected">
      <div id="source_report_container" class="rounded-xl pb-2">
        <div
          v-bind:class="[drawer ? 'xl-width' : 'xxl-width', 'left-align']" 
          fill-height
        >
          <v-col class="left-align" cols="12">
          <v-row>
            <v-col cols="8">
              <h3>Source Report</h3>
            </v-col>
            <v-col cols="2">
              <DateFilter
                :start_date="start_date"
                :end_date="end_date"
                @onSubmit="submitFilter"
              />
            </v-col>
            <v-col cols="2">
              <VueJsonToCsv
                :json-data="csvData"
                :csv-title="csvName"
                :labels="csvLabels"
              >
                <v-btn
                  large
                  color="#24588D"
                  height="40px"
                  width="110px"
                  class="rounded-l btn-export mr-2 mt-2 ml-10"
                >
                  <v-icon> mdi-cloud-download-outline </v-icon>
                  <span class="white--text ml-2"> Export</span>
                </v-btn>
              </VueJsonToCsv>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row v-if="loading">
            <v-col cols="12">
              <v-progress-circular
                :active="loading"
                v-if="loading"
                :size="130"
                :width="3"
                color="primary"
                absolute
                :indeterminate="loading"
              >
                Preparing your report...
              </v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="hasData">
            <v-col cols="12">
              <v-data-table
                :v-if="pipeLineSelected"
                :headers="showHeaders"
                :items="showColumns"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                :key="anIncreasingNumber"
                sort-by="source"
                @page-count="pageCount = $event"
              >
                <template v-slot:body="{ items }">
                  <tr
                    v-for="item in items"
                    :key="item.source_pipeline_id"
                    class="data-table-row"
                  >
                    <td class="source-data-cell">
                      <div class="source-text">
                        {{ humanize(item.source) }}
                      </div>
                      <div class="source-btn">
                        <SourceCostAddForm
                          :source_pipeline_id="item.source_pipeline_id"
                          @refreshReport="refreshData"
                          :isEdit="item.lead_cost != 'null'"
                          :current_source_cost_start_date="start_date"
                          :current_source_cost_end_date="end_date"
                        />
                      </div>
                    </td>
                    <td class="darker--blue-background" v-if="checkVisibility('lead_cost')">
                      $ {{ setDecimal(item.lead_cost) }}
                    </td>
                    <td class="darker--blue-background" v-if="checkVisibility('no_of_leads_tot')">
                      {{ setDecimal(item.no_of_leads_tot) }}
                    </td>
                    <template v-for="(stage, index) in item.stages">
                        <td :key="generateKey('no_of_leads', stage.stage_rank)" v-if="checkVisibility(generateKey('no_of_leads', stage.stage_rank))">
                          {{ setDecimal(stage.no_of_leads) }}
                        </td>
                        <!-- <td :key="generateKey('time_to_current_stage_', stage.stage_rank)" v-if="checkVisibility(generateKey('time_to_current_stage', stage.stage_rank))">
                          {{ item.time_to_current_stage ? setDecimal(item.time_to_current_stage): 'N/A' }}
                        </td>
                        <td :key="generateKey('total_time_to_current_stage_', stage.stage_rank)" v-if="checkVisibility(generateKey('total_time_to_current_stage', stage.stage_rank))">
                          {{ item.total_time_to_current_stage ? setDecimal(item.total_time_to_current_stage): 'N/A' }}
                        </td> -->
                      <td
                        class="light--blue-background"
                        :key="
                          generateKey(
                            'percentage_to_next_level',
                            stage.stage_rank
                          )
                        "
                        v-if="(index != item.stages.length - 1) && checkVisibility(generateKey('percentage_to_next_level', stage.stage_rank))"
                      >
                        {{ setDecimal(stage.percentage_to_next_level) }} %
                      </td>
                    </template>
                    <td class="darker--blue-background"  v-if="checkVisibility('no_of_won_leads_tot')">
                      {{ setDecimal(item.no_of_won_leads_tot) }}
                    </td>
                    <td class="darker--blue-background" v-if="checkVisibility('total_gross_revenue')">
                      $ {{ setDecimal(item.total_gross_revenue) }}
                    </td>
                    <td class="darker--blue-background"  v-if="checkVisibility('close_rate')">
                      {{ setDecimal(item.close_rate) }} %
                    </td>
                    <!-- <td class="darker--blue-background"  v-if="checkVisibility('average_close_time')">
                      {{ item.average_close_time != 'null' ? setDecimal(item.average_close_time): 'N/A' }}
                    </td> -->
                    <td class="darker--blue-background" v-if="checkVisibility('average_gross_revenue')">
                      $ {{ setDecimal(item.average_gross_revenue) }}
                    </td>
                    <td class="darker--blue-background" v-if="checkVisibility('total_lead_source_cost')">
                      $ {{ setDecimal(item.total_lead_source_cost) }}
                    </td>
                    <td class="darker--blue-background" v-if="checkVisibility('average_cost_per_sale')">
                      $ {{ setDecimal(item.average_cost_per_sale) }}
                    </td>
                    <td class="darker--blue-background" v-if="checkVisibility('profit')">
                      $ {{ setDecimal(item.profit) }}
                    </td>
                    <td class="darker--blue-background" v-if="checkVisibility('ROI')">
                      {{ item.ROI == 0 ? 'N/A' : setDecimal(item.ROI) + '%' }}
                    </td>
                  </tr>
                  <tr
                    :class="show_total ? 'border_top': '' "
                    class="data-table-row kpi-data-row"
                    flex
                    v-if="hasData && show_total"
                  >
                    <td class="source-data-cell v-align-center">
                      <div class="source-text mt-3">TOTAL</div>
                    </td>
                    <td
                      class="data-table-row avarage-cell"
                      :class="getClassName(key)"
                      v-for="(value, key) in total_data"
                      :key="key"
                    > 
                      {{ setDollarSympol(key) }}
                      {{ isPercentage(key) ?  setDecimal(averageData[key],1) : isANumber(key) ?  displayNaN(value)  : setDecimal(value, 1)  }}
                      {{ setPercentageSympol(key) }}
                    </td>
                  </tr>
                  <tr
                    class="data-table-row kpi-data-row"
                    flex
                    :class="!show_total && show_avg && !show_kpi ? 'border_top': '' "
                    v-if="hasData && show_avg "
                  >
                    <td class="source-data-cell v-align-center">
                      <div class="source-text mt-1">AVERAGES</div>
                    </td>
                    <td
                      class="data-table-row avarage-cell"
                      :class="getClassName(key)"
                      v-for="(value, key) in averageData"
                      :key="key"
                    >
                      {{ setDollarSympol(key) }}
                      {{ setDecimal(value, 1) }}
                      {{ setPercentageSympol(key) }}
                    </td>
                  </tr>
                  <tr
                    class="data-table-row kpi-data-row"
                    flex
                    :class="!show_total && show_kpi && !show_avg ? 'border_top': '' "
                    v-if="hasData && show_kpi"
                  >
                    <td class="source-data-cell">
                      <div class="source-text">KPIs</div>
                      <div class="source-btn">
                          <v-btn
                          v-if="!edit_kpi"
                          @click="edit_kpi = true"
                          elevation="0"
                          class="kpi_toggle-btn btn-add-source ml-4"
                          color="primary"
                          :ripple="false"
                          plain
                          text
                        >
                          Enter KPIs</v-btn
                        >
                        <v-btn
                          v-if="edit_kpi"
                          @click="saveKPI"
                          text
                          class="kpi_toggle-btn btn-add-source ml-4"
                          plain
                          :ripple="false"
                          color="orange"
                          elevation="0"
                        >
                          Save KPIs
                          <v-progress-circular
                            indeterminate
                            :size="20"
                            v-if="saving_kpi"
                            color="primary"
                            class="ml-3"
                          ></v-progress-circular>
                        </v-btn>
                      </div>
                    </td>
                    <td
                      class="data-table-row"
                      :class="getClassName(key)"
                      v-for="key in kpi_keys"
                      :key="key"
                    >
                      <v-text-field
                        class="ma-auto centered-input kpi_text_box"
                        min-width="25%"
                        max-width="25%"
                        dense
                        outlined
                        v-if="edit_kpi"
                        v-model="kpi_data[key]"
                      >
						{{key}}
                      </v-text-field>

                      <span v-if="!edit_kpi && kpi_data[key]">
                        {{ setDollarSympol(key) }}
                        {{ kpi_data[key] }}
                        {{ setPercentageSympol(key) }}
                      </span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="hasEmpty">
            <v-col>
              <v-alert
                dense
                elevation="3"
                outlined
                width="80%"
                class="ma-auto"
                text
                type="info"
              >
                There is no source report available for this time range.
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="sticky-footer" v-if="hasData">
			<v-col cols="1" class="ml-5">
              <v-switch v-model="show_total" primary inset label="Total"></v-switch>
            </v-col>
            <v-col cols="1" class="ml-5">
              <v-switch v-model="show_kpi" primary inset label="KPIs"></v-switch>
            </v-col>
            <v-col cols="1">
              <v-switch
                class="ml-5"
                v-model="show_avg"
                primary
                inset
                label="Averages"
              ></v-switch>
            </v-col>
            <v-col cols="10"></v-col>
          </v-row>
          <v-row v-if="hasData">
            <v-col cols="7">
              <div class="row-count">
                <span>{{ this.rows.length }}</span> Results
              </div>
            </v-col>
            <v-col cols="5">
              <div class="pagination-footer-div">
                <v-pagination v-model="page" :length="pageCount" />
              </div>
            </v-col>
          </v-row>
          </v-col>
        </div>
        <div v-bind:class="[drawer ? 'm-width' : 'sm-width', 'left-align']" fill-height>
          <v-col
          cols="12"
          fill-height
          class="left-align">
            <ColumnFilterConfig
              :items="filterColumnHeaders"
              :selectedItems="selectedColumns"
              :drawer="drawer"
              :default_columns="defaultHeaders"
              @update="filterColumn"
              @showConfigDrawer="showSidebarNavMenu"
              @hideConfigDrawer="hideSidebarNavMenu"
            />
        </v-col>
        </div>
      </div>
    </v-row>
    <v-row v-if="!pipeLineSelected" class="pieline-alert">
      <v-alert dense type="info" width="96%" class="ma-auto">
        Please select <strong>Pipeline</strong>
      </v-alert>
    </v-row>
  </div>
</template>
<script>
import DashBoardService from '../../services/DashBoardService'
import DateFilter from '../../components/common/DateFilter'
import SOURCE_REPORT_CONSTANT from '../../constants/SOURCE_REPORT'
import COLUMN_PROPERTY from '../../constants/COLUMN_PROPERTY'
import humanizeString from 'humanize-string'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import _ from 'lodash'
import Promise from 'bluebird'
import SourceCostAddForm from '../../components/source_cost/SourceCostAddForm'
import ColumnFilterConfig from '../../components/common/ColumnFilterConfig.vue'

export default {
  name: 'SourceReport',
  data() {
    return {
		anIncreasingNumber: 1,
		toggleSelect: false,
		headers: [],
    filterColumnHeaders: [],
		rows: [],
		page: 1,
		drawer: false,
		reports: ['Source','Salesrep'],
		selectedReport: "Source",
		sourceReport: [],
		sortBy: 'source',
		sortDesc: false,
		start_date: this.calculateStartDate(),
		end_date: new Date().toISOString().substr(0, 10),
		pageCount: 0,
		loading: true,
		itemsPerPage: 10,
		pipelines: [],
		selectedSourcePipelineId: null,
		selectedPipeline: [],
		selectedHeaders: ['source'],
		defaultHeaders: ['source'],
		csvData: [],
		csvLabels: {},
		averageData: {},
		pipeline_loading: false,
		location_id: '',
		kpi_keys: [],
		kpi_data: {},
		total_data: {},
		edit_kpi: false,
		show_kpi: false,
		show_total: false,
		show_avg: false,
		saving_kpi: false,
    pipeline_selected: false,
		column_property: {},
		emptyMessage: 'There is no data available for this pipeline',
    }
  },
  props: ['pipeline_id', 'date_from', 'date_to'],
  components: {
    DateFilter,
    VueJsonToCsv,
	ColumnFilterConfig,
    SourceCostAddForm,
  },
  created() {
    this.start_date = this.date_from || this.start_date
    this.end_date = this.date_to || this.end_date
    
    window.parent.postMessage(this.location_id, '*')


    window.addEventListener(
      'message',
      async (e) => {
        if (e.data.location_id && e.data.location_id != 'location') {
          this.location_id = e.data.location_id
          console.log("-----check--uppser stream----",e.data.location_id)
          const pipelineResponse = await this.getPipelines()
          this.pipelines = pipelineResponse.data
        }
        else {
            let location_index = 4;
            console.log("-----check------",window.location.href.split('/')[3])
            if(window.location.href.split('/')[3] == 'v2'){
                console.log("---v2--- found")
                location_index = 5
            }
            this.location_id = window.location.href.split('/')[location_index];
        }
        return true
      },
      false
    )
  },
  async mounted() {
    //to handle v2 version
    console.log("-----mounted------",window.location.href.split('/')[3])

    let location_index = 4;
    if(window.location.href.split('/')[3] == 'v2'){
        location_index = 5
    }
    this.location_id = window.location.href.split('/')[location_index];   
    
     if (this.pipeline_id) {
      const queryParams = {
        pid: this.pipeline_id,
        f_dt: this.start_date,
        t_dt: this.end_date,
      }
      await Promise.all([
        this.loadPipelines(),
        this._loadData(queryParams)
      ])
      this.selectedPipeline = this.pipeline_id
    }
  },
  computed: {
 
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s.value));
    },
    selectedColumns(){
        return (this.selectedHeaders.length > 1 ? this.selectedHeaders : _.map(this.headers, 'value'))
    },
		csvName() {
      return 'source_report_' + this.start_date + '-' + this.end_date
    },
    showColumns() {
      return this.rows;
    },
    pipeLineSelected() {
      return !!this.pipeline_id || this.pipeline_selected
    },
    totalHeaders() {
      return this.headers.length
    },
    hasData() {
      return this.rows.length > 0
    },
    hasEmpty() {
      return this.rows.length == 0 && !this.loading
    },
    months() {
      return moment.months().map((month, index) => {
        return {
          id: index + 1,
          text: month,
        }
      })
    },
  },
 
  methods: {
    hideSidebarNavMenu() {
        this.drawer = false;
    },
    showSidebarNavMenu() {
        this.drawer = true;
    },
    isANumber(column_key){
      return !this.isPercentage(column_key) && !this.isAmount(column_key)
    },
    isAmount(key){
      return this.column_property[key].dollar == '$'
    },
    isPercentage(key){
      return this.column_property[key].percentage == '%'
    },
    toggleConfigDrawer(){
      this.drawer = !this.drawer
    },
    checkVisibility(column_key){
      return this.selectedHeaders.includes(column_key)
    },
    async filterColumn(selectedHeaders){
      this.selectedHeaders = selectedHeaders    
      this.headers.forEach(header => {
        if(this.selectedHeaders.includes(header.value)){
          this.column_property[header.value].is_visible = true
        }
        else{
          this.column_property[header.value].is_visible = false
        }
      })
      this.averageData = await this.buildAverageObject()
      this.total_data = await this.buildTotalObject()
      this.kpiDataParse()

    },
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      const oldIndex = evt.oldIndex;
      const newIndex = evt.newIndex;
      if (newIndex >= headersTmp.length) {
        let k = newIndex - headersTmp.length + 1;
        while (k--) {
          headersTmp.push(undefined);
        }
      }
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.table = headersTmp;
      this.anIncreasingNumber += 1;
    },
    gotoSalesReport() {
      if(this.pipeline_id){
        this.$router.push({
          name: 'pipeline-sales-report',
          params: {
            location_id: this.location_id,
            date_from: this.start_date,
            date_to: this.end_date,
            pipeline_id: this.pipeline_id
          },
        })
      }
      else{
         this.$router.push({
          name: 'sales-report',
          params: {
            location_id: this.location_id,
            date_from: this.start_date,
            date_to: this.end_date,
          },
        })
      }
     
    },
    makeAverageKey(key) {
      return 'average_' + key
    },
    async loadPipelines() {
      try {
        const pipelineResponse = await this.getPipelines()
        this.pipelines = pipelineResponse.data
      } catch (error) {
        this.pipeline_loading = false
        this.pipelines = [{
          pipeline_id: "inavlid",
          pipeline_name: "No pipelines are available",
          disabled: true
        }]
      }
    },
    setPercentageSympol(key) {
      return this.column_property[key]
        ? this.column_property[key].percentage
        : ''
    },
    setDollarSympol(key) {
      return this.column_property[key] ? this.column_property[key].dollar : ''
    },
    getClassName(key) {
      return this.column_property[key] ? this.column_property[key].class : []
    },

    async saveKPI() {
      const dashBoardService = new DashBoardService()
      this.saving_kpi = true
      await dashBoardService.addSourceKPI(this.pipeline_id, this.kpi_data)
      this.saving_kpi = false
      this.edit_kpi = false
    },
    async getPipelines() {
      try {
        this.pipeline_loading = true
        const dashBoardService = new DashBoardService()
        const pipelineResponse = await dashBoardService.getPipelines(
          this.location_id
        )
        this.pipeline_loading = false
        return pipelineResponse
      } catch (error) {
        this.pipelines = []
      }
    },
    async selectPipeline(pipeline_id) {
      this.loading = true
      const queryParams = {
        pid: pipeline_id,
        f_dt: this.start_date,
        t_dt: this.end_date,
      }
      this.rows = []
      this.pipeline_selected = true
      await this._loadData(queryParams)
      this.pipeline_selected = false
      this.loading = false
      this.$router.push({
        name: 'pipeline-source-report',
        params: {
          pipeline_id,
          location_id: this.location_id,
          date_from: this.start_date,
          date_to: this.end_date,
        },
      })
    },
    async formatCsvData() {
      this.csvData = []
      this.csvLabels = {}
      this.csvData = await Promise.map(this.rows, async (row) => {
        const stages = row.stages
        await Promise.each(stages, (stage) => {
          row[`no_of_leads_${stage.stage_rank}`] = this.setDecimal(
            stage.no_of_leads
          )
          row[`percentage_to_next_level_${stage.stage_rank}`] = this.setDecimal(
            stage.percentage_to_next_level
          )
        })
        return row
      })
      await Promise.map(this.headers, (header) => {
        this.csvLabels[header.value] = { title: header.text }
      })
    },
    calculateStartDate() {
      var date = new Date()
      date.setMonth(date.getMonth() - 1)
      return date.toISOString().substr(0, 10)
    },
    async submitFilter(start_date, end_date) {
      this.start_date = start_date
      this.end_date = end_date
      const queryParams = {
        pid: this.pipeline_id,
        f_dt: this.start_date,
        t_dt: this.end_date,
      }
      await this._loadData(queryParams)
      this.$router.push({
        name: 'pipeline-source-report',
        params: {
          pipeline_id: this.pipeline_id,
          location_id: this.location_id,
        },
        query: {
          date_from: this.start_date,
          date_to: this.end_date,
        },
      })
    },
    async refreshData(start_date, end_date) {
      this.start_date = start_date
      this.end_date = end_date
      await this.submitFilter(start_date, end_date)
    },
    setDecimal(value, precision = 0) {
      const number =  Number.parseFloat(value).toFixed(precision)
      const converted =  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return isNaN(parseFloat(converted)) ?  'N/A' : converted
    },
    displayNaN(number){
      return isNaN(number) ?  'N/A' : number
    },
    humanize(string) {
      string = string || 'Un Assigned'
      return humanizeString(string).toUpperCase()
    },
    generateKey(text, stage_level) {
      return text + '_' + stage_level
    },
    async parseHeader(data) {
      const pre_source_columns = SOURCE_REPORT_CONSTANT.HEADERS.pre_source
      const sources = await this.buildStageHeaders(data[0].stages)
      const post_source_columns = SOURCE_REPORT_CONSTANT.HEADERS.post_source
      const filterColumStageHeaders = await this.buildFilterStageColumnHeader(data[0].stages)
      this.filterColumnHeaders = [pre_source_columns, filterColumStageHeaders, post_source_columns].flat()
      return [pre_source_columns, sources, post_source_columns].flat()
    },
    async _loadData(queryParams = {}) {
      this.rows = []
      this.headers = []
      this.loading = true
      const dashBoardService = new DashBoardService()
      try {
        const { data } = await dashBoardService.sourceReports(queryParams)
        this.kpi_data = await dashBoardService
          .getSourceKPI(queryParams.pid)
          .then((resp) => {
            return Promise.resolve(JSON.parse(resp.data[0].source_kpi))
          })
          .catch(() => {
            return Promise.resolve({})
          })
        if (data.length > 0) {
			this.headers = await this.parseHeader(data)
      this.selectedHeaders  = this.selectedHeaders.length > 1 ? this.selectedHeaders : _.map(this.headers, 'value')
			if (data.length > 0) {
				this.rows = data
			}
			} else {
				this.rows = []
			}
			await this.formatCsvData()
			this.kpiDataParse();
			this.averageData = await this.buildAverageObject()
			this.total_data = await this.buildTotalObject()
			this.loading = false
      } catch (error) {
        this.rows = []
      }
      this.loading = false
    },
	kpiDataParse(){
		this.kpi_keys = this.headers.map(({ value }) => {
			if (this.checkVisibility(value)) return value
		})
    this.kpi_keys.shift()
		this.kpi_keys = _.compact(this.kpi_keys)
	},
    async buildAverageObject() {
      var sumObject = {}
      var total_report = this.csvData.length
      const headerKeys = Object.keys(this.csvLabels)
      const filteredKeys = headerKeys.filter((e) => e !== 'source')
      await Promise.each(this.csvData, (element) => {
        filteredKeys.forEach((header_key) => {
          if(this.checkVisibility(header_key)){
            if (Object.prototype.hasOwnProperty.call(sumObject, header_key)) {
              sumObject[header_key] =
                sumObject[header_key] + parseFloat(element[header_key]) / total_report
              } else {
              sumObject[header_key] = parseFloat(element[header_key]) / total_report
            } 
          }
        })

        const total_leads = this.csvData.reduce((total, obj) => obj.no_of_leads_tot + total,0)
        const total_spend = this.csvData.reduce((total, obj) => (obj.lead_cost * obj.no_of_leads_tot) + total,0)
        sumObject['lead_cost'] = total_spend / total_leads

      })
      return sumObject
    },
    async buildTotalObject() {
      let total_object = {}
      const headerKeys = Object.keys(this.csvLabels)
      const filteredKeys = headerKeys.filter((e) => e !== 'source')
      await Promise.each(this.csvData, (element) => {
          filteredKeys.forEach((header_key) => {
            if(this.checkVisibility(header_key)){
              if (Object.prototype.hasOwnProperty.call(total_object, header_key)) {
                total_object[header_key] = parseFloat(total_object[header_key]) + parseFloat(element[header_key])
              } else {	
                total_object[header_key] = parseFloat(element[header_key])
              }
            }
          })      
      })
      total_object['lead_cost'] = 0

      await Promise.each(this.csvData, (element) => {
          total_object['lead_cost'] = total_object['lead_cost'] + (element['no_of_leads_tot'] * element['lead_cost']);
      });

      return total_object
    },
    buildStageHeaders(stages = []) {
      this.column_property = COLUMN_PROPERTY.SOURCE
      return stages.flatMap((stage, index) => {
        if (index == stages.length - 1) {
          this.column_property['no_of_leads_'+stage.stage_rank] = { is_visible: true }
          // this.column_property['total_time_to_current_stage_'+stage.stage_rank] = { is_visible: true }
          // this.column_property['time_to_current_stage_'+stage.stage_rank] = { is_visible: true }
          return [
            {
              class: 'table-header',
              text: this.humanize(stage.stage_name),
              value: 'no_of_leads_' + stage.stage_rank,
            },
            // {
            //   class: 'table-header',
            //   text:
            //     'AVG. TIME TO ' + this.humanize(stages[index].stage_name),
            //   value: 'time_to_current_stage_' + stage.stage_rank,
            // },
            // {
            //   class: 'table-header',
            //   text:
            //     'AVG. TOTAL TIME TO ' + this.humanize(stages[index].stage_name),
            //   value: 'total_time_to_current_stage_' + stage.stage_rank,
            // },
          ]
        } else {

          this.column_property[
            `percentage_to_next_level_${stage.stage_rank}`
          ] = {
            class: ['light--blue-background'],
            percentage: '%',
            is_visible: true
          }
          this.column_property[
            'no_of_leads_' + stage.stage_rank
          ] = {
            is_visible: true
          }
          // this.column_property[
          //   'time_to_current_stage_' + stage.stage_rank
          // ] = {
          //   is_visible: true
          // }
          // this.column_property[
          //   'total_time_to_current_stage_' + stage.stage_rank
          // ] = {
          //   is_visible: true
          // }

          return [
            {
              class: 'table-header',
              text: this.humanize(stage.stage_name),
              value: 'no_of_leads_' + stage.stage_rank,
            },
            // {
            //   class: 'table-header',
            //   text:
            //     'AVG. TIME TO ' + this.humanize(stages[index].stage_name),
            //   value: 'time_to_current_stage_' + stage.stage_rank,
            // },
            // {
            //   class: 'table-header',
            //   text:
            //     'AVG. TOTAL TIME TO ' + this.humanize(stages[index].stage_name),
            //   value: '_' + stage.stage_rank,
            // },
            {
              class: 'table-header',
              text:
                '% TO ' + this.humanize(stages[index + 1].stage_name),
              value: 'percentage_to_next_level_' + stage.stage_rank,
            },
          ]
        }
      })
    },
    buildFilterStageColumnHeader(stages = []) {
      return stages.flatMap((stage, index) => {
        if (index == stages.length - 1) {
          return [
              {
                stage_name: stage.stage_name,
                stage_rank: stage.stage_rank,
                stage_columns:  [
                  {
                    class: 'table-header',
                    text: this.humanize(stage.stage_name),
                    value: 'no_of_leads_' + stage.stage_rank,
                  },
                  // {
                  //   class: 'table-header',
                  //   text:
                  //     'AVG. TIME TO ' + this.humanize(stages[index].stage_name),
                  //   value: 'time_to_current_stage_' + stage.stage_rank,
                  // },
                  // {
                  //   class: 'table-header',
                  //   text:
                  //     'AVG. TOTAL TIME TO ' + this.humanize(stages[index].stage_name),
                  //   value: 'total_time_to_current_stage_' + stage.stage_rank,
                  // }
                ]
              }
          ]
        } else {
          return [
                {
                    stage_name: stage.stage_name,
                    stage_rank: stage.stage_rank,
                    stage_columns:  [ 
                        {
                            class: 'table-header',
                            text: this.humanize(stage.stage_name),
                            value: 'no_of_leads_' + stage.stage_rank,
                        },
                        // {
                        //     class: 'table-header',
                        //     text:
                        //         'AVG. TIME TO ' + this.humanize(stages[index].stage_name),
                        //     value: 'time_to_current_stage_' + stage.stage_rank,
                        // },
                        // {
                        //     class: 'table-header',
                        //     text:
                        //         'AVG. TOTAL TIME TO ' + this.humanize(stages[index].stage_name),
                        //     value: 'total_time_to_current_stage_' + stage.stage_rank,
                        // },
                        {
                            class: 'table-header',
                            text:
                                '% TO ' + this.humanize(stages[index + 1].stage_name),
                            value: 'percentage_to_next_level_' + stage.stage_rank,
                        },
                    ]
                }
          ]
        }
      })
    }
  } 
}
</script>

<style scoped>
.pagination-footer-div {
  float: right;
  margin-bottom: 20px;
}
.display-flex {
  display: flex;
}
#source_report_container {
  width: 100%;
  margin: 0px auto;
  border: 1px solid #dfdfdf;
  padding: 2px;
  box-shadow: 1px gray;
  border: 1px solid #494a4a;
  background-color: #ffffff;
}
.left-align{
  float: left;
}
#source_report_container button {
  text-transform: none;
  border: 3px solid #f2f7fa;
  color: #f0f0f0;
  font-size: 12px;
}
tr:nth-of-type(odd) {
  background-color: F1F1F1;
}
tr:nth-of-type(even) {
  background-color: #f0f0f0;
}
.v-progress-circular {
  width: 100px;
}
.xl-width{
 width: 85%;
}
.xxl-width{
 width: 97%;
}
.sm-width{
 width: 2%;
}
.m-width{
 width: 15%;
}
.source-data-cell {
  justify-content: left;
  min-width: 200px;
  max-width: 300px;
  position: sticky;
  z-index: 999;
  background-color: #F9FAFB;
}
.bring-top{
  z-index: 1050;
}
.config-container{
  box-shadow: 0px 0px 3px #00000029;
  border-radius: 0px 20px 20px 0px;
  opacity: 1;
}
.row-count {
  font-size: 16px;
  justify-content: left;
  color: #5a5959;
  margin-left: 1em;
  font-weight: 600;
  float: left;
}
.row-count span {
  color: black;
  font-weight: 900;
}
.source-text {
  word-wrap: break-word;
  display: inline;
  font-weight: 600;
  height: 100%;
  font-size: 10px !important;
  top: 35%;
  float: left;
  width: 55%;
  margin-top: 10px;
}
.btn-export {
  font-weight: 600;
  float: right;
  font-size: 10px !important;
  padding: 0px;
}
table tr {
  border-bottom: 3px solid#F2F4F6;
}
table .source-data-cell {
  word-wrap: break-word;
  justify-content: left;
  background-color: #F9FAFB;
  left: 0px
}
.dialog-box-text {
  font-size: 14px;
  font-weight: 600;
}
.light--blue-background {
  background-color: rgba(231, 243, 254, 0.5);
}
.darker--blue-background {
  background-color: rgb(181, 220, 255, 0.5);
}
.v-input__slot {
  background-color: #ffffff;
}
.kpi-data-row {
  background-color: #f2f4f6 !important;
  height: 65px;
}
.kpi_toggle-btn {
  border: none !important;
  text-decoration: underline;
  font-weight: 600;
}
.col {
  padding: 0px;
  padding-top: 5px;
}
::v-deep .kpi_text_box {
  margin-top: 30px ;
  width: 40px;
  top: 15px;
}
tr.border_top td {
  border-top: 2px solid #CDD0D4;
}
.centered-input >>> input {
  text-align: center;
}
.source-btn{
  float: left;
  width: 40%;  
}
div fieldset {
  background-color: white !important;
}
.avarage-cell {
  font-weight: 600;
}
</style>
