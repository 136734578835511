<template>
  <v-menu
    v-model="menu1"
    :nudge-right="33"
    :nudge-top="20"
    :close-on-content-click="close_menu"
    transition="scale-transition"
    offset-y
    width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        label="Filter by Date"
        prepend-icon="mdi-calendar"
        class="mt-2"
        readonly
        width="50"
        dense
        outlined
        @click="setData"
        height="35"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date_range"
      range
      :max="today"
      @change="validateRange"
    >
      <template #default>
        <v-btn
          large
          color="primary"
          height="30px"
          dense
          width="110px"
          :disabled="!can_filter"
          class="rounded-l white--text btn-filter ml-5"
          @click="submit"
        >
          <v-icon small> mdi-filter-outline </v-icon>
          <span class="white--text ml-2"> Filter</span>
        </v-btn>
        <v-btn
          large
          color="green"
          height="30px"
          dense
          width="110px"
          class="rounded-l white--text btn-filter ml-5"
          @click="cancel"
        >
          <span class="white--text ml-2"> cancel</span>
        </v-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'DateFilter',
  data: () => ({
    menu1: false,
    modal: false,
    menu2: false,
    close_menu: false,
    today: new Date().toISOString().substr(0, 10),
    date_range: [],
    can_filter: false
  }),
  props: {
    start_date: {
      type: String,
    },
    end_date: {
      type: String,
    },
  },
  mounted() {
    this.date_range = [this.start_date, this.end_date]
  },

  methods: {
    setData(){
      this.close_menu = false
    },

    submit() {
      if (this.date_range.length < 2) {
        this.close_menu = false
        return false
      }
      this.date_range= this.date_range.sort()
      this.$emit('onSubmit', this.date_range[0], this.date_range[1])
      this.close_menu = true
    },
    cancel() {
      this.date_range = [this.start_date, this.end_date]
      this.close_menu = true
    },
    validateRange() {
      if (this.date_range.length > 1){
        this.can_filter = true
      }
      else {
        this.close_menu = false
      }
    },
  },
  computed: {
    dateRangeText() {
      return this.date_range.join(' ~ ')
    }
  },
  directives: {
    'click-outside': {
        bind (){
            this.event = event => this.vm.$emit(this.expression, event)
            this.el.addEventListener('click', this.stopProp)
            document.body.addEventListener('click', this.event)
        },   
        unbind() {
          this.el.removeEventListener('click', this.stopProp)
          document.body.removeEventListener('click', this.event)
        },
        stopProp(event) { event.stopPropagation() }
    }
  }
}
</script>
<style scoped>
.btn-filter {
  float: left;
  font-weight: 600 !important;
}
.row {
  margin-right: 0px;
}
</style>