import axios from 'axios'
class DashBoardService{

  constructor() {
    this.api = this._initializeApi();
  }
  sourceReports(queryParams) {
    return this.api.get('/source',{
        params:  queryParams 
      }
    );
  }

  salesReports(queryParams) {
    return this.api.get('/salesrep',{
        params:  queryParams 
      }
    );
  }
  getPipelines(location_id){
    return this.api.get('/pipelines',{
      params:  {
        location_id,
      }
    });
  }
  addSourceCost(source_pipeline_id,start_date,end_date,amount,unitary){
    return this
      .api
      .post('/source_costs/',
        {
          sp_id: source_pipeline_id,
          f_dt: start_date,
          t_dt: end_date,
          s_cost: amount,
          unitary: unitary
        }
      );
  }
  addSourceKPI(pid,kpi_data){
      return this
        .api
        .post('/source_kpi_post/',{ pid, s_kpi: JSON.stringify(kpi_data)});
  }
  getSourceKPI(pid){
      return this
        .api
        .get('/source_kpi_get',{
          params: {
              pid
            }
        })
  }
  addSalesKPI(pid,kpi_data){
      return this
        .api
        .post('/sales_kpi_post/',{ pid, s_kpi: JSON.stringify(kpi_data)});
  }
  susbcriptionStatus(location_id){
      return this
        .api
        .get('/check_location?location_id='+location_id);
  }
  getSalesKPI(pid){
      return this
        .api
        .get('/sales_kpi_get',{
          params: {
              pid
            }
        })
  }

   _initializeApi() {

    return new axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      timeout: 800000  

      // headers: {
      //  'x-access-tokens': localStorage.getItem('userToken')
      // }
    });
  }

}

export default DashBoardService
