module.exports =  {
    HEADERS:{
        pre_source : [
            {
                text: 'SALES REPRESENTATIVE',
                class: 'table-header source-header',
                sortable: true,
                value: 'sales_rep_name',
            },
            {
                text: 'TOTAL LEADS',
                class: 'table-header',
                sortable: true,
                value: 'no_of_leads_tot'
            },
            {
                text: 'LEAD COST',
                class: 'table-header',
                sortable: true,
                value: 'total_cost_per_lead'
            }
        ],
        post_source: [
            {
                text: 'WON',
                class: 'table-header',
                sortable: true,
                value: 'no_of_won_leads_tot'
            },
            {
                text: 'REVENUE',
                class: 'table-header',
                sortable: true,
                value: 'total_gross_revenue'
            },
            {
                text: 'CLOSE RATE',
                class: 'table-header',
                sortable: true,
                value: 'close_rate'
            },
            // {
            //     text: 'AVERAGE ClOSE TIME',
            //     class: 'table-header',
            //     sortable: true,
            //     value: 'average_close_time'
            // },
            {
                text: 'AVERAGE GROSS REVENUE',
                class: 'table-header',
                sortable: true,
                value: 'average_gross_revenue'
            },
            {
                text: 'TOTAL SPENT',
                class: 'table-header',
                sortable: true,
                value: 'average_total_lead_source_cost'
            },
            {
                text: 'AVERAGE COST PER SALE',
                class: 'table-header',
                sortable: true,
                value: 'average_cost_per_sale'
            },
            {
                text: 'PROFIT',
                class: 'table-header',
                sortable: true,
                value: 'profit'
            },
            {
                text: 'ROI',
                class: 'table-header',
                sortable: true,
                value: 'ROI'
            }
        ]
    }
}