module.exports =  {
    SOURCE: {
        source: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true,
        },
        lead_cost: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        no_of_leads_tot: {
            class: ['darker--blue-background'],
            is_visible: true
        },
        total_gross_revenue: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        no_of_won_leads_tot: {
            class: ['darker--blue-background'],
            dollar: '',
            is_visible: true
        },
        // average_close_time: {
        //     class: ['darker--blue-background'],
        //     dollar: '',
        //     is_visible: true,
        // },
        close_rate: {
            class: ['darker--blue-background'],
            percentage: '%',
            is_visible: true
        },
        average_gross_revenue: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        total_lead_source_cost: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        average_cost_per_sale: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        profit: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        ROI: {
            class: ['darker--blue-background'],
            percentage: '%',
            is_visible: true
        },
    },
    SALES: {
        sales_rep_name: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        
        total_gross_revenue: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        no_of_leads_tot:{
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        average_close_time: {
            class: ['darker--blue-background'],
            dollar: '',
            is_visible: true,
        },
        total_cost_per_lead:{
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        no_of_won_leads_tot: {
            class: ['darker--blue-background'],
            dollar: '',
            is_visible: true
        },
        close_rate: {
            class: ['darker--blue-background'],
            percentage: '%',
            is_visible: true       
        },
        average_gross_revenue: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        total_lead_source_cost: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        average_total_lead_source_cost: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        average_cost_per_sale: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        profit: {
            class: ['darker--blue-background'],
            dollar: '$',
            is_visible: true
        },
        ROI: {
            class: ['darker--blue-background'],
            percentage: '%',
            is_visible: true
        },
    }
}
