<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    min-width="468px"
    content-class="rounded-xl"
    transition="dialog-top-transition"
  >
    <v-flex style="position: relative">
      <v-btn
        v-if="dialog"
        fab
        class="close-button topright"
        absolute
        elevation="0"
        medium
        color="#F3FBFB"
        @click="closeDialogBox"
      >
        <v-icon fab>mdi-close</v-icon>
      </v-btn>
    </v-flex>
    <template v-slot:activator="{ on, attrs }">
      <div class="v-align-center">
        <v-btn
          min-width="100px"
          max-width="110px"
          class="btn-add-source white--text pa-1"
          :color="isEdit ? '#156d10' : '#5094DD' "
          rounded
          v-bind="attrs"
          v-on="on"
          @click="setSourceCostDialog()"
        >
          <span>
            {{ isEdit ? 'Edit Lead Cost' : 'Add Lead Cost'}}
          </span>
        </v-btn>
      </div>
    </template>
    <v-card class="pa-5" v-if="firstDialog" color="#F3FBFB">
      <v-card-title
        class="justify-center pt-10 pb-5 pl-5 pr-10 dialog-header-text"
      >
        How do you want to assign your lead cost?
      </v-card-title>
      <v-card-text>
        <v-expansion-panels tile accordion class="set-background">
          <v-expansion-panel>
            <v-expansion-panel-header> <span><v-icon class="mr-2">mdi-lightbulb-on-outline</v-icon> Note </span></v-expansion-panel-header>
            <v-expansion-panel-content>
              If you know how much each lead costs (for example, you are buying
              from a lead provider), then click "Assign Lead Cost”. If you know
              how much money you spent to generate these leads but do not know
              your actual lead cost (for example, you are running advertising
              campaigns), then click “Calculate Lead Cost.
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#FFFF"
          rounded
          class="dialog-submit"
          @click="selectMonthlySourceCost"
        >
          Assign Lead Cost
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="#FFFF"
          rounded
          class="dialog-submit"
          @click="selectUnitorySourceCost"
        >
          Calculate Lead Cost
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card
      class="pa-10"
      transition="slide-x-transition"
      v-if="unitary === 1"
      color="#F3FBFB"
    >
      <v-form @submit.prevent="submitSourceCost" v-model="isFormValid">
        <v-card-title
          class="justify-center pt-16 pb-16 pl-10 pr-10 dialog-header-text"
        >
          Input your lead cost below
        </v-card-title>
        <v-card-text class="dialog-box-text">
          <v-row v-if="!!alert">
            <v-col cols="12">
              <v-alert
                color="orange"
                dense
                dismissible
                elevation="3"
                outlined
                text
                type="error"
              >
                {{ alert }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4"> Please select start date </v-col>
            <v-col cols="8">
              <v-menu
                v-model="date_picker_menu_start"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="source_cost_start_date"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="source_cost_start_date"
                  show-current="source_cost_start_date"
                  :max="source_cost_end_date"
                  @input="date_picker_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"> Please select end date </v-col>
            <v-col cols="8">
              <v-menu
                v-model="date_picker_menu_end"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="source_cost_end_date"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="source_cost_end_date"
                  show-current="source_cost_end_date"
                  :max="today"
                  :min="source_cost_start_date"
                  @input="date_picker_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"><span> Lead cost($) </span> </v-col>
            <v-col cols="8">
              <v-text-field
                outlined
                :rules="[rules.required, rules.numberRequired]"
                class="white--text"
                v-model.number="source_cost_amount"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#FFFF" rounded class="dialog-submit" @click="previous">
            previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#157811"
            rounded
            type="submit"
            primary
            :disabled="!isFormValid"
            :loading="isCalculating"
            class="dialog-form-submit"
          >
            <span class="pa-7 white--text">Assign Lead Cost</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-card
      class="pa-10"
      transition="slide-x-transition"
      v-if="unitary === 0"
      color="#F3FBFB"
    >
      <v-form @submit.prevent="submitSourceCost" v-model="isFormValid">
        <v-card-title
          class="justify-center pt-16 pb-16 pl-10 pr-10 dialog-header-text"
        >
          Input your total spend below
        </v-card-title>
        <v-card-text class="dialog-box-text">
          <v-row>
            <v-col cols="4">Please select start date</v-col>
            <v-col cols="8">
              <v-menu
                v-model="date_picker_menu_start"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="source_cost_start_date"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="source_cost_start_date"
                  show-current="source_cost_start_date"
                  :max="source_cost_end_date"
                  @input="date_picker_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">Please select end date</v-col>
            <v-col cols="8">
              <v-menu
                v-model="date_picker_menu_end"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    v-model="source_cost_end_date"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="source_cost_end_date"
                  show-current="source_cost_end_date"
                  :max="today"
                  :min="source_cost_start_date"
                  @input="date_picker_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"
              ><span> Total spend over this period ($) </span></v-col
            >
            <v-col cols="8">
              <v-text-field
                outlined
                :rules="[rules.required, rules.numberRequired]"
                class="form-text-box"
                v-model.number="source_cost_amount"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#FFFF" rounded class="dialog-submit" @click="previous">
            Previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#157811"
            rounded
            type="submit"
            primary
            :disabled="!isFormValid"
            :loading="isCalculating"
            class="dialog-form-submit"
          >
            <span class="pa-7 white--text"> Calculate Lead Cost</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import DashBoardService from '../../services/DashBoardService'

export default {
  data() {
    return {
      alert: null,
      dialog: false,
      date_picker_menu: false,
      date_picker_menu_end: false,
      date_picker_menu_start: false,
      source_cost_amount: 0,
      today: new Date().toISOString().substr(0, 10),
      source_cost_start_date: new Date().toISOString().substr(0, 10),
      source_cost_end_date: new Date().toISOString().substr(0, 10),
      firstDialog: true,
      unitary: null,
      isFormValid: false,
      isCalculating: false,
      source_cost_month: new Date().toISOString().substr(0, 7),
      rules: {
        required: (value) => value >= 0 || 'Required cost',
        numberRequired: (value) =>
          typeof value == 'number' || 'It should be a number',
      },
    }
  },
  props: {
    source_pipeline_id: {
      type: String,
    },
    isEdit: {
      type: Boolean,
    },
    current_source_cost_start_date: {
      type: String,
    },
    current_source_cost_end_date: {
      type: String,
    },
  },
  mounted() {
    this.source_cost_start_date =
      this.current_source_cost_start_date || this.source_cost_start_date
    this.source_cost_end_date =
      this.current_source_cost_end_date || this.source_cost_end_date
  },
  computed: {
    valid() {
      return true
    },
  },
  methods: {
    selectUnitorySourceCost() {
      this.firstDialog = false
      this.unitary = 0
    },
    selectMonthlySourceCost() {
      this.firstDialog = false
      this.unitary = 1
    },

    closeDialogBox() {
      this.dialog = false
      this.firstDialog = false
      this.unitary = false
      this.source_cost_amount = 0
    },
    previous() {
      this.firstDialog = true
      this.unitary = null
      this.source_cost_amount = 0
    },
    setSourceCostDialog() {
      this.firstDialog = true
      this.selectedSourcePipelineId = this.source_pipeline_id
    },
    async submitSourceCost() {
      this.isCalculating = true
      const dashBoardService = new DashBoardService()
      const start_date = moment(this.source_cost_start_date).format('Y-MM-DD')
      const end_date = moment(this.source_cost_end_date).format('Y-MM-DD')
      try {
        await dashBoardService.addSourceCost(
          this.selectedSourcePipelineId,
          start_date,
          end_date,
          this.source_cost_amount,
          this.unitary
        )
        this.closeDialogBox()
        this.$emit('refreshReport', start_date, end_date)
      } catch (error) {
        this.isCalculating = false
        this.alert =
          'Please check that leads have been generated for this period'
      }
    },
  },
}
</script>

<style lang="css" scoped>
.btn-add-source {
  font-weight: 900;
  display: inline;
  word-wrap: break-word;
  top: 25%;
  font-size: 8px !important;
}
.dialog-header-text {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.dialog-submit {
  min-width: 8em;
  max-width: 15em;
  height: 4.37em;
  border: 1px solid gray !important;
}
.dialog-form-submit {
  min-width: 10em;
  height: 4.37em;
}
.close-button {
  white-space: nowrap;
  background-color: transparent;
}
.topright {
  right: 0;
}
.v-expansion-panel-header {
  background-color: #f3fbfb;
}
.v-expansion-panel::before{
  box-shadow: none !important;
}
.v-expansion-panel{
    background-color: #f3fbfb !important;
}
.v-expansion-panel-content__wrap {
  background-color: #f3fbfb;
}
.btn-add-source span{
  font-size: 8px;
}
</style>