<template>
  <div id="app">
    <v-app>
      <v-main class="app-background">
        <v-container fluid>
            <v-banner
              elevation="2"
              single-line
              align="center"
              sticky
              outlined
              dense
              class="pt-2"
              height="50px"
              v-if="!isActive"
              :color="bannerColor"
            >
            {{message}}
            </v-banner>
           <router-view>
             
           </router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import {SUBSRIPTIONS} from './constants/MESSAGES'
import DashBoardService from './services/DashBoardService'


export default {
  name: 'App',
  data(){
    return {
      status: 'active',
      susbcription_status: {},
    }
  },
    async mounted(){
      //to handle v2 version
      let location_index = 4;
      if(window.location.href.split('/')[3] == 'v2'){
        location_index = 5
      }
      console.log("--------------updated------------------")
      const location_id = window.location.href.split('/')[location_index];
      if(location_id){
        const dashboard = new DashBoardService()
        this.susbcription_status = await dashboard.susbcriptionStatus(location_id);
        this.status = this.susbcription_status.data.status
      }
    },
    computed: {
      isActive(){
        return this.status == 'active'
      },
      message(){
        return SUBSRIPTIONS[this.status].message
      },
      bannerColor(){
        return SUBSRIPTIONS[this.status].color
      }
    }
  }

</script>

<style>
#app {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* table tr{
  background-color: #F9FAFB !important;
} */
.container{
  padding: 0px !important;
}
.v-banner__text{
  font-weight: bold;
}
.app-background{
  background-color: #F9FAFC
}
.v-input{
  font-size: 12px !important;
}
.v-input__slot{
  background-color: #F9FAFC
}
 .table-header{
    font-size: 12px !important;
    background-color: #F9FAFB;
    font-weight: 900;
    position: sticky;
    min-width:  2em;
    min-height: 2em !important;
    max-height: 4em !important;
    border-bottom: 3px solid #cdd0d4 !important;
  }
  .v-data-table{
    width: 100%;
  }
  .v-banner__content{
    color: #F9FAFC;
  }
  .data-table-row{
    min-height: 50px;
    max-height: 150px;
    font-size: 10px !important;
    font-weight: 600 ;
    color: #3E3E3E;
    text-align: center;
    
  }
  tbody{
    display: block;
  }
  table .source-data-cell{
    border-right: 3px solid #cdd0d4;
    height: 100%;
    text-align: left;
    margin-left: 10px;
    min-height: 25px;
    max-height: 75px;
    font-size: 12px !important;
    padding: 10px;
  }
  
  .source-header{
    border-bottom: 2px solid #cdd0d4;
    margin-left: 10px;
    background-color: #F9FAFB;
    left: 0;
    border-right: 3px solid #cdd0d4;
    position: sticky;
    z-index: 100;
  }
  .v-pagination button{
    height: 45px !important; 
    width: 45px !important;
  }
  .v-pagination__item--active{
    background-color: #178BF6 !important;
  }
  fieldset{
    background-color: #FFFF !important;
  } 
  .row{
    margin: 0px !important;
  }
</style>
